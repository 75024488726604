import React, { FC, useState } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';

import Navbar from 'components/Navbar';

import theme from './theme.scss';

const ResetPasswordForm: FC<{
  onSubmit: (username: string, password: string) => void;
  loading: boolean;
  error?: {};
}> = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');

  const { onSubmit, loading, error } = props;

  function verifyPasswords(username: string, password: string, confirmPassword: string) {
    const passwordValidator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    switch (true) {
      case username.length < 8:
        setFormError('Username must be at least 10 characters long');
        break;
      case password.length < 8:
        setFormError('Password must be at least 8 characters long');
        break;
      case !passwordValidator.test(password):
        setFormError(
          'Password must contain a combination of atleast one uppercase, one lower case, one number and one special characters (#?!@$%^&*-).'
        );
        break;
      case password !== confirmPassword:
        setFormError('Both passwords must match.');
        break;
      default:
        setFormError('');
        onSubmit(username, password);
    }
  }

  return (
    <div>
      <Navbar size={'thin'} />
      <Grid className={theme.resetPasswordForm} textAlign="center" style={{ height: '100vh' }}>
        <Grid.Column widescreen={4} largeScreen={4} computer={6} tablet={7} mobile={14}>
          <div className={theme.formContainer}>
            <h1>Reset Password</h1>
            <Form size="large" error={!!error}>
              <Form.Input
                fluid
                placeholder="Phone Number"
                onChange={e => setUsername(e.target.value)}
              />
              <Form.Input
                fluid
                placeholder="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
              <Form.Input
                fluid
                placeholder="ConfirmPassword"
                type="password"
                onChange={e => setConfirmPassword(e.target.value)}
              />
              {formError || error ? (
                <Message negative>
                  <div>
                    <b>{formError || error}</b>
                  </div>
                </Message>
              ) : null}
              <Button
                color="teal"
                basic
                fluid
                size="large"
                loading={loading}
                onClick={() => verifyPasswords(username, password, confirmPassword)}
              >
                Set
              </Button>
            </Form>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ResetPasswordForm;
