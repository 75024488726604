import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';

import DefaultLayout from 'layouts/DefaultLayout';
import AppBadges from 'components/AppBadges';

import chinese from 'media/chinese.png';

import theme from './theme.scss';

const Home: FC = () => {
  const history = useHistory();

  return (
    <DefaultLayout>
      <div className={theme.home}>
        <div className={theme.content}>
          <div className={theme.banner} style={{ backgroundImage: `url(${chinese})` }} />
          <p className={theme.tagline}>
            Cuttle helps you pre-order at restaurants and food courts so you can avoid queues and
            pay with ease.
          </p>
          <AppBadges />
          <div className={theme.footer}>
            <Button.Group size="tiny">
              <Button onClick={() => history.push('/about')}>About</Button>
              <Button onClick={() => history.push('/privacypolicy')}>Privacy</Button>
              <Button onClick={() => history.push('/terms')}>Terms</Button>
              <Button onClick={() => history.push('/usagepolicy')}>Usage</Button>
            </Button.Group>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Home;
