import renderRoutes from 'utils/renderRoutes';
import App from 'components/App';
import { isAuthenticated } from 'utils/auth';
import home from './home/route';
import terms from './terms/route';
import about from './about/route';
import privacypolicy from './privacypolicy/route';
import usagepolicy from './usagepolicy/route';
import notFound from './default/route';
import login from './login/route';
import resetpassword from './resetpassword/route';
import setpassword from './setpassword/route';

const routeConfigs = {
  redirects: [
    {
      path: '/login',
      to: '/',
      exact: true,
      condition: isAuthenticated,
    },
    {
      path: '/cancellationpolicy',
      to: '/usagepolicy',
      exact: true,
    },
  ],
  component: App,
  routes: [
    home,
    terms,
    about,
    privacypolicy,
    usagepolicy,
    login,
    setpassword,
    resetpassword,
    notFound,
  ],
};

export default () => {
  return renderRoutes(routeConfigs);
};
