import React, { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import Title from 'components/Title';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.scss';

const Terms: FC = () => {
  return (
    <DefaultLayout>
      <div className={theme.terms}>
        <Title name="Terms of Service" backButtonRoute="/" />
        <Divider />
        <div className={theme.content}>
          <h2>Terms</h2>
          <p>
            By accessing the websites at http://cuttle.cc or the iOS or Android app, Cuttle by
            Illude Design, you are agreeing to be bound by these terms of service, all applicable
            laws and regulations, and agree that you are responsible for compliance with any
            applicable local laws. If you do not agree with any of these terms, you are prohibited
            from using or accessing this site. The materials contained in this website are protected
            by applicable copyright and trademark law.
          </p>
          <h2>Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the materials (information or
            software) on Illude Design's, Cuttle Platform website for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a transfer of title, and
            under this license you may not: modify or copy the materials; use the materials for any
            commercial purpose, or for any public display (commercial or non-commercial); attempt to
            decompile or reverse engineer any software contained on Illude Design's website; remove
            any copyright or other proprietary notations from the materials; or transfer the
            materials to another person or "mirror" the materials on any other server. This license
            shall automatically terminate if you violate any of these restrictions and may be
            terminated by Illude Design at any time. Upon terminating your viewing of these
            materials or upon the termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed format.
          </p>
          <h2>Disclaimer</h2>
          <p>
            The materials on Illude Design's website are provided on an 'as is' basis. Illude Design
            makes no warranties, expressed or implied, and hereby disclaims and negates all other
            warranties including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights. Further, Illude Design does not warrant or make
            any representations concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such materials or on any sites
            linked to this site.
          </p>
          <p>
            <h2>Limitations</h2>
            In no event shall Illude Design or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business interruption)
            arising out of the use or inability to use the materials on Illude Design's website,
            even if Illude Design or a Illude Design authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some jurisdictions do
            not allow limitations on implied warranties, or limitations of liability for
            consequential or incidental damages, these limitations may not apply to you.
          </p>
          <h2>Accuracy of materials</h2>
          <p>
            The materials appearing on Illude Design's website could include technical,
            typographical, or photographic errors. Illude Design does not warrant that any of the
            materials on its website are accurate, complete or current. Illude Design may make
            changes to the materials contained on its website at any time without notice. However
            Illude Design does not make any commitment to update the materials.
          </p>
          <h2>Links</h2>
          <p>
            Illude Design has not reviewed all of the sites linked to its website and is not
            responsible for the contents of any such linked site. The inclusion of any link does not
            imply endorsement by Illude Design of the site. Use of any such linked website is at the
            user's own risk.
          </p>
          <h2>Modifications</h2>
          <p>
            Illude Design may revise these terms of service for its website at any time without
            notice. By using this website you are agreeing to be bound by the then current version
            of these terms of service.
          </p>
          <h2>Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of
            Karnataka(India) and you irrevocably submit to the exclusive jurisdiction of the courts
            in that State or location.
          </p>
          <h2>Contact Us</h2>
          <p>For any issues or clarifications, you can write to us or call.</p>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Terms;
