import React, { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import Title from 'components/Title';

import refund from 'media/refund.png';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.scss';

const UsagePolicy: FC = () => {
  return (
    <DefaultLayout>
      <div className={theme.terms}>
        <Title name="Usage Policy" backButtonRoute="/" />
        <Divider />
        <div className={theme.content}>
          <h2>Provider of the Site and App</h2>
          <p>Illude Design Private Limited</p>
          <p>4th Floor, Sreedevi Classics, </p>
          <p>Anugraha Layout, B - Narayanapura, </p>
          <p>Bangalore - 560016</p>
          <h2>Contact Us</h2>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p>
        </div>
        <Divider />
        <div className={theme.content}>
          <h2>Cancellation Policy</h2>
          <p>
            As a general rule you shall not be entitled to cancel your order once you have received
            confirmation of the same. If you cancel your order after it has been confirmed, Illude
            Design (Cuttle) shall have a right to charge you cancellation fee of a minimum INR 75
            upto the order value, with a right to either not to refund the order value or recover
            from your subsequent order, the complete/ deficit cancellation fee, as applicable, to
            compensate our restaurant and delivery partners. Illude Design(Cuttle) shall also have
            the right to charge you cancellation fee for the orders cancelled by Illude Design
            (Cuttle) for the reasons specified under clause 1(iii) of this cancellation and refunds
            policy. In case of cancellations for the reasons attributable to Illude Design (Cuttle)
            or its restaurant, Illude Design (Cuttle) shall not charge you any cancellation fee.
          </p>
          <p>
            However, in the unlikely event of an item on your order being unavailable, we will
            contact you on the phone number provided to us at the time of placing the order and
            inform you of such unavailability. In such an event you will be entitled to cancel the
            entire order and shall be entitled to a refund in accordance with our refund policy.
          </p>
          <p>We reserve the sole right to cancel your order in the following circumstances:</p>
          <ul>
            <li>
              failure to contact you by phone or email at the time of confirming the order booking
            </li>
            <li>unavailability of all the items ordered by you at the time of booking the order</li>
          </ul>
          <h2>Refund</h2>
          <p>
            You shall be entitled to a refund only if you pre-pay for your order at the time of
            placing your order on the Platform and only in the event of any of the following
            circumstances:
          </p>
          <ul>
            <li>
              us cancelling your order due to (A) failure to contact you by phone or email at the
              time of confirming the order booking; or (B) failure to contact you by phone or email
              at the time of confirming the order booking; or
            </li>
            <li>
              you cancelling the order at the time of confirmation due to unavailability of the
              items you ordered for at the time of booking
            </li>
          </ul>
          <p>
            Our decision on refunds shall be at our sole discretion and shall be final and binding.
          </p>
          <p>
            All refund amounts shall be credited to your account within 3-4 business days in
            accordance with the terms that may be stipulated by the bank which has issued the credit
            / debit card.
          </p>
          <img className={theme.refundProcess} src={refund} alt="refund policy" />
          <h2>Delivery Policy</h2>
          <p>
            Illude Design is not involved in the delivery of the order and does not offer the
            service. The customer needs to pickup the order at the location or contact the vendor
            directy.
          </p>
          <p>The above policies are effective as of 18 January 2020.</p>
          {/* <h2>Contact Us</h2>
          <p>For any issues or clarifications, you can write to us or call.</p>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p> */}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default UsagePolicy;
