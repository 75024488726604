import React, { FC } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import cuttlelogo from 'media/cuttlewordlogo.png';

import theme from './theme.scss';

const DefaultLayout: FC = props => {
  return (
    <div className={theme.defaultLayout}>
      <div className={theme.content}>
        <Grid>
          <Grid.Row centered>
            <Grid.Column widescreen={6} largeScreen={6} computer={9} tablet={12} mobile={15}>
              <div className={theme.logo} style={{ backgroundImage: `url(${cuttlelogo})` }} />
              {props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(DefaultLayout);
