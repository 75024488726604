import React, { FC, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import { useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import DefaultLayout from 'layouts/DefaultLayout';
import ResetPasswordForm from 'components/ResetPasswordForm';
import AppBadges from 'components/AppBadges';

import resetPasswordMutation from 'graphql/mutations/resetPassword.graphql';

import theme from './theme.scss';

const ResetPassword: FC = () => {
  const [resetPassword, { loading }] = useMutation(resetPasswordMutation);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { code } = useParams();

  const onSubmit = async (username: string, password: string) => {
    try {
      await resetPassword({
        variables: {
          code,
          username,
          password,
        },
      });
      setError('');
      setSuccess(true);
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        setError('Please check your credentials and try again.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  if (success) {
    return (
      <DefaultLayout>
        <div className={theme.resetPassword}>
          <h1>Your account is ready to use!</h1>
          <Divider hidden />
          <p>You can now use your phone number and password to login.</p>
          <p>
            Please download the app at the link below and login to find restaurants to order from.
          </p>
          <AppBadges />
        </div>
      </DefaultLayout>
    );
  }

  return <ResetPasswordForm onSubmit={onSubmit} loading={loading} error={error} />;
};

export default ResetPassword;
