import React, { FC } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { isAuthenticated, logOut } from 'utils/auth';

import cuttlelogo from 'media/cuttlewordlogo.png';

import theme from './theme.scss';

type SemanticWIDTHSNUMBER =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16';
type SemanticWIDTHSSTRING =
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | 'six'
  | 'seven'
  | 'eight'
  | 'nine'
  | 'ten'
  | 'eleven'
  | 'twelve'
  | 'thirteen'
  | 'fourteen'
  | 'fifteen'
  | 'sixteen';

type ColumnSizes = {
  widescreen: SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING | undefined;
  largeScreen: SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING | undefined;
  computer: SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING | undefined;
  tablet: SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING | undefined;
  mobile: SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING | undefined;
};

const Navbar: FC<{ size?: string }> = ({ size = 'wide' }) => {
  function columnSizes(): ColumnSizes {
    switch (size) {
      case 'thin':
        return {
          widescreen: '9',
          largeScreen: '9',
          computer: '11',
          tablet: '12',
          mobile: '15',
        };
      default:
        return {
          widescreen: '16',
          largeScreen: '16',
          computer: '16',
          tablet: '16',
          mobile: '16',
        };
    }
  }

  return (
    <Grid>
      <Grid.Row centered>
        <Grid.Column
          className={theme.navColumn}
          style={size !== 'wide' ? { padding: '0' } : null}
          {...columnSizes()}
        >
          <div className={theme.nav}>
            <span className={theme.branding}>
              <img className={theme.logo} src={cuttlelogo} alt="cuttle logo" />
            </span>
            {isAuthenticated() ? (
              <div className={theme.logout} onClick={logOut} title="Logout">
                <Icon name="log out" />
              </div>
            ) : null}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Navbar;
