import React, { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import Title from 'components/Title';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.scss';

const PrivacyPolicy: FC = () => {
  return (
    <DefaultLayout>
      <div className={theme.terms}>
        <Title name="Privacy Policy" backButtonRoute="/" />
        <Divider />
        <div className={theme.content}>
          <h2>Provider of the Site and App</h2>
          <p>Illude Design Private Limited</p>
          <p>4th Floor, Sreedevi Classics, </p>
          <p>Anugraha Layout, B - Narayanapura, </p>
          <p>Bangalore - 560016</p>
          <h2>Contact Us</h2>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p>
        </div>
        <Divider />
        <div className={theme.content}>
          <p>Your privacy is important to us.</p>
          <p>
            It is Illude Design's policy to respect your privacy regarding any information we may
            collect from you across our website, https://illude.co, https://cuttle.cc, Cuttle mobile
            apps, and other sites we own and operate.
          </p>
          <p>
            We only ask for personal information when we truly need it to provide a service to you.
            We collect it by fair and lawful means, with your knowledge and consent. We also let you
            know why we’re collecting it and how it will be used.
          </p>
          <p>
            We only retain collected information for as long as necessary to provide you with your
            requested service. What data we store, we’ll protect within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access, disclosure, copying,
            use or modification.
          </p>
          <p>
            We don’t share any personally identifying information publicly or with third-parties,
            except when required to by law.
          </p>
          <p>
            Our website may link to external sites that are not operated by us. Please be aware that
            we have no control over the content and practices of these sites, and cannot accept
            responsibility or liability for their respective privacy policies.
          </p>
          <p>
            You are free to refuse our request for your personal information, with the understanding
            that we may be unable to provide you with some of your desired services.
          </p>
          <p>
            Your continued use of our website will be regarded as acceptance of our practices around
            privacy and personal information. If you have any questions about how we handle user
            data and personal information, feel free to contact us.
          </p>
          <p>This policy is effective as of 18 January 2020.</p>
          {/* <h2>Contact Us</h2>
          <p>For any issues or clarifications, you can write to us or call.</p>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p> */}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
