import React, { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import Title from 'components/Title';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.scss';

const PrivacyPolicy: FC = () => {
  return (
    <DefaultLayout>
      <div className={theme.terms}>
        <Title name="Company Details" backButtonRoute="/" />
        <Divider />
        <div className={theme.content}>
          <h2>Provider of the Site and App</h2>
          <p>Illude Design Private Limited</p>
          <p>4th Floor, Sreedevi Classics, </p>
          <p>Anugraha Layout, B - Narayanapura, </p>
          <p>Bangalore - 560016</p>
          <h2>Contact Us</h2>
          <p>Email: support@illude.co</p>
          <p>Phone: +91-9380563989</p>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
