import React, { FC } from 'react';

import appstoreicon from 'media/appstore.svg';

import theme from './theme.scss';

const AppBadges: FC = () => {
  return (
    <div className={theme.appBadges}>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.illude.cuttleapp&hl=en_IN">
          <img
            alt="Get it on Google Play"
            height={60}
            className={theme.playstoreIcon}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
      <div>
        <a href="https://apps.apple.com/in/app/cuttle/id1501301259">
          <img
            alt="Get it on Apple Appstore"
            height={46}
            className={theme.appstoreIcon}
            src={appstoreicon}
          />
        </a>
      </div>
    </div>
  );
};

export default AppBadges;
